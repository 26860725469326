import React from "react"
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
export default function Sectheree() {
  return (
    <>
      <Container fluid className="__bg-primary-sl-mirror">
        <Container>
          <Row className="fade-in">
            <Col lg={12}>
              <div className="__introduction">
                <p className="text-center">Introducing</p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="__image_logo-sl">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/logo-am-love.png"
                  alt="logo"
                  className="img img-fluid lazyload"
                />
              </div>
            </Col>
          </Row>
          <Row className="fade-in __margin-top-sl-50">
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-white text-center">
                  Ketika Join Kelas Ini, Kamu Akan Dapatkan :
                </h2>
              </div>
            </Col>
          </Row>

          <Accordion defaultActiveKey="0">
            <Card>
              {/* <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <h2 className="__text-headline-class">
                    VIP Bundling Attraction Mastery Online Training
                  </h2>
                </Accordion.Toggle>
              </Card.Header> */}
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _a">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Devices.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Teknik menanam benih </b> cinta pada otak pria
                            yang kamu suka TANPA nembak dan TANPA takut ditolak
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Mengerti bagaimana cara menciptakan ketertarikan
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Gak takut kehabisan pria berkualitas</b> karena
                            kamu bisa menciptakan ketertarikan pada pria
                            berkualitas lainnya
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>
                              Mampu membuat pria yang kamu suka, menyukaimu
                              balik
                            </b>
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari{" "}
                            <b>kesalahan yang bisa membunuh ketertarikan</b>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  {/* <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _b">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1workbook-am-min.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi rangkuman materi yang memudahkanmu tidak
                            perlu repot-repot membuat rangkuman sendiri
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _c">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1cheatsheet-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi contekan visual yang memudahkanmu memahami
                            isi materi.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _d">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1audio-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="__margin-top-class-singel">
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel pt-1">
                            Akses audio rekaman selama 30 hari
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Kamu bisa mengulang kembali materi dalam bentuk
                            audio.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _e">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1coaching-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex mt-3">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Sesi khusus 60 Menit bersama Coach Jose Aditya
                            dimana kamu dan peserta VIP lainnya mendapat
                            privilege Q & A mengenai materi yang tidak didapat
                            di kelas reguler.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white mb-4">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _f">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Thumbnail-lj-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Love Journey adalah 2 Hari Elite Training (virtual)
                            yang bisa membantu Kamu jadi wanita menarik dan
                            cenderung mudah menemukan pria tepat yang ingin
                            menjalin komitmen jangka panjang. <br />
                            <br /> Diadakan secara virtual pada 24 - 25 Juli
                            2021 Training ini senilai Rp 12.649.000 namun kamu
                            hanya membayar Rp 6.997.000 jika mengambil kelas VIP
                            Bundling dengan Love Journey Elite Virtual Training.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _f">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/laptop-fim.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            BONUS FREE ACCESS 30 hari Online Course First
                            Impression Make Over, selama 30 hari senilai Rp
                            497.000 <br />
                            <br />
                            Online course ini bisa membantumu mengubah pertemuan
                            pertama yang menegangkan jadi tak terlupakan
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mt-2">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <h2 className="__text-headline-class">
                    VIP Attraction Mastery Online Training
                  </h2>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _a">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Devices.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Teknik menanam benih </b> cinta pada otak pria
                            yang kamu suka TANPA nembak dan TANPA takut ditolak
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Mengerti bagaimana cara menciptakan ketertarikan
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Gak takut kehabisan pria berkualitas</b> karena
                            kamu bisa menciptakan ketertarikan pada pria
                            berkualitas lainnya
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>
                              Mampu membuat pria yang kamu suka, menyukaimu
                              balik
                            </b>
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari{" "}
                            <b>kesalahan yang bisa membunuh ketertarikan</b>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _b">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1workbook-am-min.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi rangkuman materi yang memudahkanmu tidak
                            perlu repot-repot membuat rangkuman sendiri
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _c">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1cheatsheet-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi contekan visual yang memudahkanmu memahami
                            isi materi.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _d">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1audio-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="__margin-top-class-singel">
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel pt-1">
                            Akses audio rekaman selama 30 hari
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Kamu bisa mengulang kembali materi dalam bentuk
                            audio.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _e">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1coaching-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex mt-3">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Sesi khusus 60 Menit bersama Coach Jose Aditya
                            dimana kamu dan peserta VIP lainnya mendapat
                            privilege Q & A mengenai materi yang tidak didapat
                            di kelas reguler.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _f">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/laptop-fim.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            BONUS FREE ACCESS 30 hari Online Course First
                            Impression Make Over, selama 30 hari senilai Rp
                            497.000 <br />
                            <br />
                            Online course ini bisa membantumu mengubah pertemuan
                            pertama yang menegangkan jadi tak terlupakan
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mt-2">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <h2 className="__text-headline-class">
                    Regular CLASS Attraction Mastery Online Training
                  </h2>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Devices.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Teknik menanam benih </b> cinta pada otak pria
                            yang kamu suka TANPA nembak dan TANPA takut ditolak
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Mengerti bagaimana cara menciptakan ketertarikan
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>Gak takut kehabisan pria berkualitas</b> karena
                            kamu bisa menciptakan ketertarikan pada pria
                            berkualitas lainnya
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            <b>
                              Mampu membuat pria yang kamu suka, menyukaimu
                              balik
                            </b>
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari{" "}
                            <b>kesalahan yang bisa membunuh ketertarikan</b>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _b">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1workbook-am-min.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi rangkuman materi yang memudahkanmu tidak
                            perlu repot-repot membuat rangkuman sendiri
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _d">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/rsz_1audio-am-min-v.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="__margin-top-class-singel">
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel pt-1">
                            Akses audio rekaman selama 3 hari
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Kamu bisa mengulang kembali materi dalam bentuk
                            audio.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
          </Accordion>
        </Container>
      </Container>
    </>
  )
}
